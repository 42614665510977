module.exports
{

    app = angular.module('app', ['ui.router','ngStorage','vcRecaptcha','ngMask','ui-notification']); //'angular-uuid','angucomplete-alt','ui.utils.masks'
    serverurl='https://donation.anoopam.org/api/v1';

   // console.log(IS_DEV);

    app.run(['$rootScope', '$state', '$stateParams','$transitions','$localStorage','$timeout',function ( $rootScope,   $state,   $stateParams,$transitions,$localStorage,$timeout ) {
            $rootScope.$state = $state;
            $rootScope.$stateParams = $stateParams;
            $transitions.onSuccess({}, function() {
                if($rootScope.signIn){
                    $timeout(function () {
                        $localStorage.lastvisit = window.location.href; // a})DwCu{@gg{
                    },2000)
                }
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            });
    }]);

    app.run(function ($rootScope, $templateCache) {
        $rootScope.$on('$viewContentLoaded', function () {
            $templateCache.removeAll();
        });
    });

    app.config(function(NotificationProvider) {
        NotificationProvider.setOptions({
            delay: 10000,
            startTop: 20,
            startRight: 10,
            verticalSpacing: 20,
            horizontalSpacing: 20,
            positionX: 'left',
            positionY: 'bottom'
        });
    });

    app.config(['$locationProvider', function ($locationProvider) {
        //$locationProvider.html5Mode({enabled: true});
        $locationProvider.hashPrefix('');
    }]);

    var date = new Date().getTime().toString();

    app.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/login'); //login
        $stateProvider

        .state('unavailable', {
            url: '/unavailable',
            templateUrl: 'views/unavailable.html?t='+date,
            controller: 'unavailableController',
            cache: false,
            data: {title: 'Unavailable', theme: 'dark',hidemenu:true}
        })




        .state('registration', {
            url: '/registration',
            templateUrl: 'views/registration.html?t='+date,
            controller: 'registrationController',
            cache: false,
            data: {title: 'Registration', theme: 'dark'}
        })

        .state('login', {
            url: '/login',
            templateUrl: 'views/login.html?t='+date,
            controller: 'loginController',
            cache: false,
            data: {title: 'Login', theme: 'dark'}
        })

        .state('onetimedonation', {
            url: '/onetimedonation',
            templateUrl: 'views/onetimedonation.html?t='+date,
            controller: 'walkindonarController',
            cache: false,
            data: {title: 'One Time Donation', theme: 'dark'}
        })




        .state('donatenow', {
            url: '/donatenow',
            templateUrl: 'views/donatenow.html?t='+date,
            controller: 'donatenowController',
            cache: false,
            data: {title: 'Donate Now', theme: 'dark'}
        })

        .state('receipt', {
            url: '/receipt/:orderid/:phone',
            templateUrl: 'views/receipt.html?t='+date,
            controller: 'receiptController',
            cache: false,
            data: {title: 'Receipt', theme: 'dark'}
        })
        .state('pdf', {
            url: '/pdf/:orderid/:phone',
            templateUrl: 'views/receipt.html?t='+date,
            controller: 'receiptController',
            cache: false,
            data: {title: 'Receipt', theme: 'dark',pdf:true}
        })


        .state('editprofile', {
            url: '/editprofile',
            templateUrl: 'views/registration.html?t='+date,
            controller: 'editprofileController',
            cache: false,
            data: {title: 'Edit Profile', theme: 'dark'}
        })

        .state('donationhistory', {
            url: '/donationhistory',
            templateUrl: 'views/donationhistory.html?t='+date,
            controller: 'donationhistoryController',
            cache: false,
            data: {title: 'Donation History', theme: 'dark'}
        })

        

      

        .state('nridonation', {
            url: '/nridonation',
            templateUrl: 'views/nridonation.html?t='+date,
            controller: 'nridonationController',
            cache: false,
            data: {title: 'NRI Donation', theme: 'dark'}
        })

        

    }]);

}


