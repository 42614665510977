 

app.directive('ngUpdate', [function () {
    return {
        require: '?ngModel',
        link: function (scope, elem, attrs, ctrl) {
            if (!ctrl) return;
            var elementValue;
            var changevalue = false;
            elem.bind('blur', function(event) {
                if(elementValue != elem.val().trim() && changevalue){
                    scope.$eval(attrs.ngUpdate);
                }
            });
            elem.bind('change',function (event) {
                changevalue = true;
            })
            elem.bind('focus', function(event) {
                elementValue = elem.val();
                changevalue = false;
            });
        }
    };
}]);



app.directive('ngMaxlength', [function () {
    return {
        require: '?ngModel',
        link: function (scope, elem, attrs, ctrl) {
            if (!ctrl) return;
            var elementValue;
            var changevalue = false;
            elem.bind('blur', function(event) {
                 if(elem.val().length!=attrs.ngMaxlength){
                     elem.focus();   
                 }
            });                          
        }
    };
}]);

  
app.directive('validFile',function(){
    return {
        require:'ngModel',
        link:function(scope,el,attrs,ctrl){
            ctrl.$setValidity('validFile', el.val() != '');
            //change event is fired when file is selected
            el.bind('change',function(){
                ctrl.$setValidity('validFile', el.val() != '');
                scope.$apply(function(){
                    ctrl.$setViewValue(el.val());
                    ctrl.$render();
                });
            });
        }
    }
})


app.directive('convertToNumber', function() {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        ngModel.$parsers.push(function(val) {
          return val != null ? parseInt(val, 10) : null;
        });
        ngModel.$formatters.push(function(val) {
          return val != null ? '' + val : null;
        });
      }
    };
  })