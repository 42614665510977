
app.service('s', ['$rootScope', '$http','$timeout','$sessionStorage','$location', function ($rootScope, $http,$timeout,$sessionStorage,$location) {

    var s = this;
    var token=true;


    if(!Boolean($sessionStorage.token)){
        //$location.path('/');
    }



    this.callback = function (action, data, method,loader=true,retry=false, callbackFunc) {

        var url =   serverurl +'/'+ action

        if (data) {
            l(data, 'data');
            data = JSON.stringify(data);
        }
        $rootScope.loader = loader;
        $http({
            method: method,
            url: url,
            dataType: 'text',
            data: data,
            timeout: retry?12000:60000
        }).then(function successCallback(response) {
            l(response, 'Callback : '+url,action);
            if (response.data.status == 'error') {
                $rootScope.notify('danger',response.data.msg);
            }

            $rootScope.loader = false;
            callbackFunc(response.data.data);
        }, function errorCallback(response) {
            l(response, 'Fail : '+url);
            $rootScope.loader = false;
            if(retry){
               callbackFunc({status:'error',code:'retry',message:'Please  wait retry again...'});
            }
            else{
                callbackFunc({status:'error',message:'Request timeout....'});
            }
        });
    };

}]);



function l(data,title) {

    //console.group(title);
    //console.log(data);
    //console.groupEnd();

}

