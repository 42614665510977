var app;
var serverurl;

  

import './scss/style.scss';

import 'bootstrap/dist/js/bootstrap.min';
import 'angular';
import '@uirouter/angularjs';
import 'ngstorage';
import 'bootstrap-validator';
import 'angular-recaptcha'; // vcRecaptcha
import 'ng-mask';
import 'angular-ui-notification/dist/angular-ui-notification.min';

import './js/dirPagination';
import './js/config';
import './js/services';
import './js/directive';
import './js/filter';
import './js/controller';





