

app.controller('AppController', ['$scope', '$rootScope', 's', '$location','$state',  '$compile', '$http', '$timeout','$localStorage','$filter', function ($scope, $rootScope, s, $location,$state,  $compile, $http,$timeout,$localStorage,$filter) {

      $scope.currentPage = 1;
      $scope.pageSize = '25';

      $scope.states = {
          "AN":"Andaman and Nicobar Islands",
          "AP":"Andhra Pradesh",
          "AR":"Arunachal Pradesh",
          "AS":"Assam",
          "BR":"Bihar",
          "CG":"Chandigarh",
          "CH":"Chhattisgarh",
          "DN":"Dadra and Nagar Haveli",
          "DD":"Daman and Diu",
          "DL":"Delhi",
          "GA":"Goa",
          "GJ":"Gujarat",
          "HR":"Haryana",
          "HP":"Himachal Pradesh",
          "JK":"Jammu and Kashmir",
          "JH":"Jharkhand",
          "KA":"Karnataka",
          "KL":"Kerala",
          "LA":"Ladakh",
          "LD":"Lakshadweep",
          "MP":"Madhya Pradesh",
          "MH":"Maharashtra",
          "MN":"Manipur",
          "ML":"Meghalaya",
          "MZ":"Mizoram",
          "NL":"Nagaland",
          "OR":"Odisha",
          "PY":"Puducherry",
          "PB":"Punjab",
          "RJ":"Rajasthan",
          "SK":"Sikkim",
          "TN":"Tamil Nadu",
          "TS":"Telangana",
          "TR":"Tripura",
          "UP":"Uttar Pradesh",
          "UK":"Uttarakhand",
          "WB":"West Bengal"
      }

     
      

     $scope.getInit = function(callback){
          s.callback('init',{},'GET',true,false,function (response) {
               $scope.init = response.init.data;
               //$scope.init.selected={categoryid:12,subcategoryid:31,towards:'Religious'};
               callback();
          });
     }

     $scope.getInit(function(){

     });

     $scope.submit= function ($event,action,data,params,method,loader,retry,callback) {

          if($event){
               var element=$($event.currentTarget);
               if($event.currentTarget.tagName == 'FORM'){
                    element = $('[type="submit"]',$event.currentTarget);
               }
               var value = element.html();
               element.attr({disabled:true}).html('<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...');
          }
          var url = action;
          if(params){
               url += jsonToQueryString(params);
          }

          s.callback(url,data,method,loader,retry,function (res) {

               let response = res[action];

               if(response){
                    if(response.code === 'retry'){
                         callback({action:'retry'});
                    }
                    else{
                         if(response.status==='success' || response.status==='info'){
                              if(callback){
                                   callback(response);
                              }
                              $rootScope.notify(response.status,response.msg);
                         }
                         if(element){
                              element.attr({disabled:false}).html(value);
                         }
                    }
               }
          });
     }

     var timer;
     $scope.filterInput = function (value) {
          $timeout.cancel(timer);
          timer = $timeout(function () {
               $rootScope.filter = value;
          },500)
     }

     $scope.replace = function (master,chunk,field) {
          const obj = ($filter('filter')(master, field, true)[0]);
          const key = master.indexOf(obj);
          master[key] = chunk;
     }

     $scope.findby = function (master,field) {
         return $filter('filter')(objToArray(master), field, true);
     }

    $scope.findandreplace = function (items,searchfield,replacewith) {
        items.forEach((element, index) => {
            if(element[searchfield] === replacewith[searchfield]) {
                items[index] = replacewith;
            }
        });
    }


     $scope.removeitem = function (master,item,callback) {
          if(confirm('Are you sure to delete?')) {
               const index = master.indexOf(item);
               master.splice(index, 1);
               return callback('deleted');
          }
          return callback('cancel');
     }


     $rootScope.notify = function (status,msg) {
          //Notification({message: msg}, status);
          $rootScope.alert = {'msg':msg,'status':status};
          $timeout(function () {
               $rootScope.alert.msg='';
          },4000);
     }

     $scope.print = function(){
          window.print();
     }

     $scope.jsontocsv = function(json,fields,filename){
          JSONToCSVConvertor(json,fields,filename,true);
     }

     $scope.isSignIn = function () {
          if($localStorage.init){
               $rootScope.loginuser = $localStorage.init;
               $rootScope.signIn=true;
               return true;
          }
          else{
               return false;
          }
     }

     $scope.logout = function(){
          $localStorage.init = false;
          $rootScope.signIn = false;
          $scope.isSignIn();
         $location.path('login');
     }



     $scope.checkPan = function(pannumber){

          var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

          if(regpan.test(pannumber)){
               return true;
          } else {
               $rootScope.notify('danger','PAN Number is wrong');
               return false;
          }
     }

     $scope.modalConfirm = function(callback){
          $("#mi-modal").modal('show');
          $("#modal-btn-yes").on("click", function(){
               $("#mi-modal").modal('hide');
               callback(true);
          });
          $("#modal-btn-no").on("click", function(){
               $("#mi-modal").modal('hide');
               callback(false);
          });
        };
}]);


app.controller('registrationController',['$scope','$rootScope','$location','$localStorage','$timeout','$filter','s','$stateParams','$window',function ($scope,$rootScope,$location,$localStorage,$timeout,$filter,s,$stateParams,$window) {
 
     $scope.verifyotp=false;
     $scope.sendOTP = function($event){
          $scope.submit($event,'registration',{'phone':$scope.data.phone,getotp:true},'','POST',false,true,function(response){
               if(response.status === 'success'){
                    $scope.getotp = response.otp;
                    $scope.verifyotp=true;
                    $scope.verifiedmobile=false;
                    $scope.data.otp = '';
               }
               $rootScope.notify(response.status,response.msg);
          });
     }


     $scope.registrationsuccess = false;

     $scope.register = function($event){
          $scope.submit($event,'registration',$scope.data,'','POST',true,true,function(response){
               $scope.registrationsuccess = true;
          });
     }

     $scope.changemobile = function(){
          $scope.verifyotp=false;
          $scope.verifiedmobile = false
     }

       $scope.verifyotp=false;
     $scope.sendOTP = function($event){
          $scope.submit($event,'otp',{'phone':$scope.data.phone,getotp:true},'','POST',false,true,function(response){
               if(response.status === 'success'){
                    $scope.getotp = response.otp;
                    $scope.verifyotp=true;
                    $scope.verifiedmobile=false;
                    $scope.data.otp = '';
               }
               $rootScope.notify(response.status,response.msg);
          });
     }

       $scope.verifyOTP = function($event){
          $scope.submit($event,'otp',{'phone':$scope.data.phone,'otp':$scope.data.otp},'','POST',false,true,function(response){
               if(response.status === 'success'){
                     $scope.verifiedmobile=true;
               }
          });
     }

}]);


app.controller('loginController',['$scope','$rootScope','$location','$localStorage','$timeout','$filter','s','$stateParams',function ($scope,$rootScope,$location,$localStorage,$timeout,$filter,s,$stateParams) {
 
    if($localStorage.init){
        $rootScope.loginuser = $localStorage.init;
        $rootScope.signIn=true;
        $location.path('/donatenow');
    }

     $scope.data = {}

     $scope.verifyotp=false;
     $scope.sendOTP = function($event){
          $scope.submit($event,'registration',{'phone':$scope.data.phone,getotp:true},'','PUT',false,true,function(response){
               if(response.status === 'success'){
                    $scope.getotp = response.otp;
                    $scope.verifyotp=true;
                    $scope.verifiedmobile=false;
                    $scope.data.otp = '';
               }
          });
     }

     $scope.verifyOTP = function($event){
          $scope.submit($event,'registration',{'phone':$scope.data.phone,'verifyotp':$scope.data.otp},'','PUT',false,true,function(response){
               if(response.status === 'success'){
                    $localStorage.otp = $scope.data.otp;
                    access(response.data);
               }
          });
     }

     function access(login) {
          $rootScope.signIn = true;
          $localStorage.init = login;
          $rootScope.loginuser = $localStorage.init.data;
          $location.path('/donatenow');
     }

}]);


app.controller('walkindonarController',['$scope','$rootScope','$location','$localStorage','$timeout','$filter','s','$stateParams','Notification',function ($scope,$rootScope,$location,$localStorage,$timeout,$filter,s,$stateParams,Notification) {

     $scope.data = {towards:'Charitable',donationtype:'Individual'}

     if(!Boolean($scope.init)){
          $scope.getInit(function(){
               $scope.data = {...$scope.data,category:$scope.init.selected.categoryid,subcategory:$scope.init.selected.subcategoryid,towards:$scope.init.selected.towards}                           
          });          
     }  
     else if($scope.init.selected){
          $scope.data = {...$scope.data,category:$scope.init.selected.categoryid,subcategory:$scope.init.selected.subcategoryid,towards:$scope.init.selected.towards}  
     }     
     
 
     $scope.register = function($event){
         $scope.submit($event,'trans',$scope.data,'','POST',false,true,function(response){
             let  url = atob(response.url);
             window.location.href=url;
         });
     }


    $scope.getMainCategoryDetails = function(){
        $scope.categorydetail = '';
        let find = $scope.findby($scope.init.category[$scope.data.towards],{categoryid:$scope.data.category});
        if(find) {
            $scope.categorydetail = find[0];
        }
    }

       $scope.getCategoryDetails = function(){
         let catdetails = $scope.init.category[$scope.data.towards][$scope.data.category];
         if(catdetails && catdetails.sub) {
             let find = $scope.findby(catdetails.sub, {categoryid: $scope.data.subcategory})
             if (find) {
                 $scope.categorydetail = find[0];
             }
         }
       }

       $scope.changemobile = function(){
          $scope.verifyotp=false;
          $scope.verifiedmobile = false
     }

       $scope.verifyotp=false;
     $scope.sendOTP = function($event){
          $scope.submit($event,'otp',{'phone':$scope.data.phone,getotp:true},'','POST',false,true,function(response){
               if(response.status === 'success'){
                    $scope.getotp = response.otp;
                    $scope.verifyotp=true;
                    $scope.verifiedmobile=false;
                    $scope.data.otp = '';
               }
               $rootScope.notify(response.status,response.msg);
          });
     }

       $scope.verifyOTP = function($event){
          $scope.submit($event,'otp',{'phone':$scope.data.phone,'otp':$scope.data.otp},'','POST',false,true,function(response){
               if(response.status === 'success'){
                     $scope.verifiedmobile=true;
               }
          });
     }

}]);

app.controller('donationhistoryController',['$scope','$rootScope','$location','$localStorage','$timeout','$filter','s','$stateParams',function ($scope,$rootScope,$location,$localStorage,$timeout,$filter,s,$stateParams) {
     $scope.isSignIn();

     let params = {uniqueregid:$localStorage.init.md5regid,otp:$localStorage.init.otp}

     $scope.getTransactions = function(){
          $scope.submit('','profile','',params,'GET',false,true,function(response){
               if(response.status == 'success'){
                   $scope.trans = response.data
               }
          });
     }
     $scope.getTransactions();


}]);

app.controller('editprofileController',['$scope','$rootScope','$location','$localStorage','$timeout','$filter','s','$stateParams',function ($scope,$rootScope,$location,$localStorage,$timeout,$filter,s,$stateParams) {
     $scope.isSignIn();


     $scope.data =  {
          "uniqueregid":$localStorage.init.md5regid,
          "otp":$localStorage.init.otp,
          "firstname": $localStorage.init.firstname,
          "middlename": $localStorage.init.middlename,
          "lastname": $localStorage.init.lastname,
          "gender": $localStorage.init.gender,
          "email": $localStorage.init.email,
          "phone": $localStorage.init.phone,
          "city": $localStorage.init.city,
          "district": $localStorage.init.district,
          "pin": $localStorage.init.pin,
          "state": $localStorage.init.state,
          "country": "IN",
          "address": $localStorage.init.address,
          "pan":$localStorage.init.pan,
         "aadhar":$localStorage.init.aadhar,
         "donationtype":$localStorage.init.donationtype
      }


     $scope.verifyotp=false;
     $scope.verifiedmobile = true;


     let params = {uniqueregid:$localStorage.init.md5regid,otp:$localStorage.init.otp}

     $scope.register = function($event){
          $scope.submit($event,'profile',$scope.data,params,'POST',false,true,function(response){
               if(response.status == 'success'){
                    $localStorage.init = {...$localStorage.init,...response.data}
               }
          });
     }

     $scope.changemobile = function(){
          $scope.verifyotp=false;
          $scope.verifiedmobile = false
     }

       $scope.verifyotp=true;
       $scope.verifiedmobile = true;
     $scope.sendOTP = function($event){
          $scope.submit($event,'otp',{'phone':$scope.data.phone,getotp:true},'','POST',false,true,function(response){
               if(response.status === 'success'){
                    $scope.getotp = response.otp;
                    $scope.verifyotp=true;
                    $scope.verifiedmobile=false;
                    $scope.data.otp = '';
               }
               $rootScope.notify(response.status,response.msg);
          });
     }

       $scope.verifyOTP = function($event){
          $scope.submit($event,'otp',{'phone':$scope.data.phone,'otp':$scope.data.otp},'','POST',false,true,function(response){
               if(response.status === 'success'){
                     $scope.verifiedmobile=true;
               }
          });
     }


}]);

app.controller('donatenowController',['$scope','$rootScope','$location','$localStorage','$timeout','$filter','s','$stateParams','Notification','$compile',function ($scope,$rootScope,$location,$localStorage,$timeout,$filter,s,$stateParams,Notification,$compile) {
     $scope.isSignIn();

     $scope.data =  $localStorage.init;
     $scope.data = {...$scope.data,towards:'Charitable'}

     if(!Boolean($scope.init)){
          $scope.getInit(function(){
               $scope.data = {...$scope.data,category:$scope.init.selected.categoryid,subcategory:$scope.init.selected.subcategoryid,towards:$scope.init.selected.towards}                                          
          });          
     }  
     else if($scope.init.selected){
          $scope.data = {...$scope.data,category:$scope.init.selected.categoryid,subcategory:$scope.init.selected.subcategoryid,towards:$scope.init.selected.towards}            
     } 
 
     $scope.donationdetail =  {
          ...$scope.donationdetail,
          "regid":$localStorage.init.md5regid,
          "phone": $localStorage.init.phone,
          "pan": $localStorage.init.pan,
          "aadhar": $localStorage.init.aadhar,
      }

     $scope.donatenow = function($event){
         $scope.submit($event,'trans',$scope.donationdetail,'','POST',false,true,function(response){
             let  url = atob(response.url);
             window.location.href=url;
         });
       }

    $scope.getMainCategoryDetails = function(){
        $scope.categorydetail = '';
        let find = $scope.findby($scope.init.category[$scope.data.towards],{categoryid:$scope.donationdetail.category});
        if(find) {
            $scope.categorydetail = find[0];
        }
    }

     $scope.getCategoryDetails = function(){
          let catdetails  = $scope.init.category[$scope.data.towards][$scope.donationdetail.category];
          if(catdetails && catdetails.sub) {
               let find = $scope.findby(catdetails.sub, {categoryid: $scope.donationdetail.subcategory});
               if(find) {
                    $scope.categorydetail = find[0];
               }
          }
     }

}]);

app.controller('receiptController',['$scope','$rootScope','$location','$localStorage','$timeout','$filter','s','$stateParams','$window',function ($scope,$rootScope,$location,$localStorage,$timeout,$filter,s,$stateParams,$window) {

    if($localStorage.init){
        $rootScope.loginuser = $localStorage.init;
        $rootScope.signIn=true;
    }

     let orderid = $stateParams.orderid;
     let phone = $stateParams.phone;


     let params = {transid:orderid,phone:phone}

     $scope.getReceipt = function(){
          $scope.submit('','trans','',params,'GET',false,true,function(response){
               if(response.status == 'success'){
                   $scope.receipt = response.data
               }
          });
         $scope.submit('','receipt','',{receiptid:orderid,phone:phone},'GET',false,true,function(response){
             if(response.status == 'success'){
                 $scope.download = response.data
             }
         });
     }
     $scope.getReceipt();
}]);



app.controller('nridonationController',['$scope','$rootScope','$location','$localStorage','$timeout','$filter','s','$stateParams','$window',function ($scope,$rootScope,$location,$localStorage,$timeout,$filter,s,$stateParams,$window) {

    $scope.countries = [{"name":"United States","dial_code":"+1","code":"US"},
        {"name":"United Kingdom","dial_code":"+44","code":"GB"},
        {"name":"Canada","dial_code":"+1","code":"CA"},
        {"name":"Australia","dial_code":"+61","code":"AU"},
        {"name":"New Zealand","dial_code":"+64","code":"NZ"},
        {"name":"Other","dial_code":"+00","code":""}];

    $scope.requestsuccess = false;

    $scope.register = function($event){
        if($scope.data.newcountry && $scope.data.country === 'Other'){
            $scope.data.country = $scope.data.newcountry;
        }
        $scope.submit($event,'nrirequest',$scope.data,'','POST',true,true,function(response){
            $scope.requestsuccess = true;
        });
    }

    $scope.getCountryCode = function (key){
        $scope.data.country = '';
        $scope.data.code = '';
        if(key) {
            $scope.data.country = $scope.init.country[key].country;
            $scope.data.code = '+'+$scope.init.country[key].countrycode;
        }
    }

}]);




function jsonToQueryString(json) {
     return '/?' +
         Object.keys(json).map(function(key) {
              return encodeURIComponent(key) + '=' +
                  encodeURIComponent(json[key]);
         }).join('&');
}

function copy(mainObj) {
     let objCopy = {};
     let key;

     for (key in mainObj) {
          objCopy[key] = mainObj[key];
     }
     return objCopy;
}

function clone(obj){
     var copy;
     if (null == obj || "object" != typeof obj) return obj;
     if (obj instanceof Date) {
          copy = new Date();
          copy.setTime(obj.getTime());
          return copy;
     }
     if (obj instanceof Array) {
          copy = [];
          for (var i = 0, len = obj.length; i < len; i++) {
               copy[i] = clone(obj[i]);
          }
          return copy;
     }
     if (obj instanceof Object) {
          copy = {};
          for (var attr in obj) {
               if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
          }
          return copy;
     }
     throw new Error("Unable to copy obj! Its type isn't supported.");
}


function printDiv() {
     window.print();
 }

function objToArray(data){
    if (Array.isArray(data)) { 
        return data;
    }
    if (data) {
        let result = [];
        for (let i in data) {
            if (i && data[i]) {
                result.push(data[i]);
            }
        }
        result.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
        return result;
    }
}


//  Https://3.23.155.150:2083
//  newsiteanoopam
//  a})DwCu{@gg{
